.mobile {
  display: block;
  @media (--small) {
    display: none;
  }
}

.desktop {
  display: none;
  @media (--small) {
    display: block;
  }
}
