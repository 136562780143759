.wrapper {
  display: flex;
  gap: var(--spacing-xs);
  flex-direction: column;
  position: relative;

  @media (--small) {
    display: grid;
    grid-column: 1 / span 12;

    gap: var(--spacing-sm) var(--column-gap);
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
  }

  &.mediaFullContentInside {
    position: absolute;
    inset: 0;

    & .textRight,
    & .textLeft {
      color: var(--color-white);
      margin-top: auto;
      margin-bottom: 190px;
      @media (--small) {
        margin-top: unset;
        margin-bottom: unset;
      }
    }
  }

  &.contents {
    @media (--small) {
      display: contents;
    }
  }
}

.heroMedia {
  width: 100%;
  &[data-layout="page"]&:not(.heroLeft) {
    @media (--mobile-only) {
      max-width: initial;
    }
  }
}

.heroFullOverlayText {
  --hero-full-height: 835px;
  --hero-full-top: 20px;

  @media (--small) {
    --hero-full-height: 90vh;
    grid-column: 1 / span 12;
    grid-row: 1 / span 3;
  }

  & > div > div {
    height: var(--hero-full-height);
    & picture {
      object-fit: cover;
      height: 166.6%;
    }
  }
}

.heroFullBottomText {
  --hero-full-height: 450px;
  --hero-full-top: 50px;
  --aspect: auto;

  & div {
    height: var(--hero-full-height);
    clip-path: inset(0);
  }

  & img {
    position: fixed;
    height: var(--hero-full-height);
    width: 100%;
  }

  @media (--small) {
    --hero-full-height: 650px;
    grid-column: 1 / span 12;
    grid-row: 1;

    & div {
      height: var(--hero-full-height);
      & picture {
        height: var(--hero-full-height);
      }
    }
  }
}

.heroLeft {
  --hero-left-top: 30vh;
  order: 2;

  &:has(picture) {
    --aspect: 5 / 4;
  }

  &:has(video) {
    --aspect: 5 / 6;
  }

  @media (--small) {
    grid-column: 1 / span 7;
    grid-row: 1;

    & > div {
      margin-top: var(--hero-left-top);
    }
  }
}

.textWrapper {
  --hero-text-top: 75px;
  display: block;
  @media (--small) {
    max-width: 90%;
    position: sticky;
    top: calc(var(--hero-text-top) + var(--header-height));
    margin-bottom: var(--spacing-sm);
    line-height: 1;
  }
}

.title {
  @extend %typo-headline1;
  word-break: break-word;
  line-height: 1;
}

.text {
  @extend %typo-subtitle2;
}

.byline {
  @extend %typo-byline;
  text-transform: uppercase;
}

/* Text alignment when media is to the left */
.textDefault {
  @media (--small) {
    padding-right: var(--spacing-16);
  }
  & .bylineDefault {
    margin-top: var(--spacing-xs);
  }

  & .text {
    margin-top: var(--spacing-xs);
  }

  @media (--small) {
    grid-column: 8 / span 5;
    grid-row: 1 / span 2;
    order: 1;

    & .bylineDefault {
      margin-top: var(--spacing-6);
    }
  }
}

/* Text alignment when media is full and text is left inside media */
.textLeft {
  @media (--small) {
    grid-row: 2;
    grid-column: 1 / span 5;
    z-index: var(--z-overlay);
    color: var(--color-white);
    margin-left: var(--spacing-40);
  }
}

/* Text alignment when media is full and text is to the right inside media */
.textRight {
  @media (--small) {
    grid-row: 2;
    grid-column: 8 / span 5;
    z-index: var(--z-overlay);
    color: var(--color-white);
    margin-right: var(--spacing-40);
  }
}

/* Text alignment when media is full and text is under media */
.titleAndBylineBottom {
  @media (--small) {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 5;
    grid-row: 2;
  }
}

.textBottom {
  @media (--small) {
    grid-column: 8 / span 5;
    grid-row: 2 / span 2;
  }
}
