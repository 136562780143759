.wrapper {
  place-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: var(--spacing-md);

  @media (--small) {
    grid-column: span 4;
    padding-bottom: unset;
  }

  &:not(.featuredArticle) {
    padding-top: var(--spacing-24);

    @media (--small) {
      padding-top: 0;
    }
  }

  & .imageContainer,
  & video {
    aspect-ratio: var(--aspect-ratio, 1 / 1);
    object-fit: cover;
    position: relative;
  }

  --aspect-ratio: 5 / 6;
  --card-width: 70%;

  &.variant3 {
    --aspect-ratio: 5 / 6;
    --card-width: 75%;

    @media (--small) {
      --card-width: 70%;
    }
  }

  &.variant2 {
    --aspect-ratio: 6 / 5;
    --card-width: 100%;

    @media (--small) {
      --card-width: 77%;
    }
  }
  &.variant1 {
    --aspect-ratio: 1 / 1;
    --card-width: 85%;

    @media (--small) {
      --card-width: 75%;
    }
  }

  & .inner {
    display: block;
    color: var(--color-dark);
    text-decoration: none;
    width: 100%;
    max-width: var(--card-width);
  }

  @media (--small) {
    &.inherit {
      grid-column: span 3;
      --card-width: 100%;
    }
  }
}

.name {
  @extend %typo-headline2;
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-6);
  text-transform: uppercase;
}

.excerpt {
  @extend %typo-body2;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  overflow: hidden;
}

.title {
  padding-top: var(--spacing-12);
  padding-right: var(--spacing-12);
  grid-row: 1;

  @media (--small) {
    padding-top: var(--spacing-8);
  }
}

.featuredArticle {
  @media (--mobile-only) {
    --overlay-top: 104px;
    position: relative;
    z-index: var(--z-above);
    top: var(--overlay-top);
  }
}
