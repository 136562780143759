.textHero {
  text-align: center;
  padding: var(--spacing-md);
  display: grid;
  place-content: center;
  gap: var(--spacing-md);
  aspect-ratio: 1 / 1;

  & .text {
    display: grid;
    gap: var(--spacing-sm);
    & .title {
      @extend %typo-headline2;
    }
  }
}

.hero {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  --aspect: var(--hero-aspect, 16 / 9);

  @media (--mobile-only) {
    max-width: 100%;
  }

  & .contentContainer {
    aspect-ratio: var(--aspect);
    position: relative;

    & picture,
    & video {
      aspect-ratio: var(--aspect);
      object-fit: cover;
    }

    & .stickyContent {
      justify-content: space-between;
    }

    & .content {
      position: absolute;
      inset: 0;
      padding: var(--spacing-sm);
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      & .headerAndTitle {
        display: flex;
        color: var(--color-white);
        flex-direction: column;
        align-items: start;
        text-align: left;
        height: min-content !important;

        &.sticky {
          position: sticky;
          bottom: var(--spacing-32);
        }
      }
    }

    & .heading {
      @extend %typo-headline1;
      color: var(--color-white);
      margin-bottom: var(--spacing-sm);
    }

    & .title {
      @extend %typo-headline2;
      /* color: var(--color-white); */
      width: fit-content;
      text-decoration: none;
      display: flex;
      gap: var(--spacing-8);
      align-items: center;

      @media (--small) {
        text-align: left;
      }

      @media (--small) {
        text-align: var(--hero-alignment, center);
      }

      &.link {
        --svg-color: currentColor;
        border-bottom: 1px solid currentColor;
      }
    }
  }

  & .description {
    @extend %typo-body2;
  }

  & .link {
    @extend %typo-body2;
    text-decoration: underline;
    text-underline-offset: 2px;
    transition: text-decoration-thickness 0.2s;
    color: inherit;
    @media (hover: hover) {
      &:hover {
        text-decoration-thickness: 1.5px;
      }
    }
  }
}
