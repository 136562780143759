.textHero {
  text-align: center;
  padding: var(--spacing-md);
  display: grid;
  place-content: center;
  gap: var(--spacing-md);
  aspect-ratio: 1 / 1;

  & .text {
    display: grid;
    gap: var(--spacing-sm);
  }
}

.hero {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  --aspect: var(--hero-aspect, 16 / 9);

  width: 100%;
  &[data-layout="page"] {
    @media (--mobile-only) {
      max-width: initial;
    }
  }

  & .contentContainer {
    aspect-ratio: var(--aspect);
    position: relative;
    width: inherit;
    & picture {
      aspect-ratio: var(--aspect);
      object-fit: cover;
      height: 560px;

      @media (--small) {
        height: auto;
      }
    }

    & .content {
      --y-space: var(--spacing-sm);
      position: absolute;
      inset: 0;
      padding: var(--y-space) var(--spacing-xs);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      right: unset;

      @media (--small) {
        --y-space: var(--spacing-32);
        padding: var(--y-space) var(--spacing-sm);
      }

      & .sticky {
        position: sticky;
        bottom: var(--y-space);
      }
    }

    & .title {
      @extend %typo-hero;
      color: var(--color-white);
      text-align: left;
      width: 100%;

      &:not(.sticky) {
        margin-top: auto;
      }

      &.sticky {
        position: sticky;
        top: var(--spacing-lg);
      }
    }
  }

  & .description {
    @extend %typo-body2;
  }

  & .linkWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16);
    margin-top: var(--spacing-24);

    @media (--small) {
      gap: var(--spacing-32);
      margin-top: var(--spacing-26);
    }

    @media (--small) {
      flex-direction: row;
    }

    & .links {
      border-bottom: 1px solid var(--color-white);
      text-decoration: none transparent;
      width: max-content;
    }

    & .link {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: var(--color-white);
      @extend %typo-explore;
    }

    & svg {
      --arrow-height: 18px;
      --arrow-width: 28px;
      --svg-color: var(--color-white);
      margin-left: var(--spacing-8);
      width: var(--arrow-width);
      height: var(--arrow-height);

      & path {
        stroke-width: 1px;
      }
    }
  }
}
