.wrapper:not(.column) {
  @extend %main-grid;

  & .inner {
    @extend %main-left-large;
  }

  &.hasTitle {
    & h2 {
      grid-row: 1;
      @media (--small) {
        grid-column: 1 / 2 span;
      }
    }

    & .inner {
      grid-column: 1;
      @media (--small) {
        grid-column: 3 / 13;
      }
    }

    & p,
    & h2,
    & h3 {
      @extend %typo-subtitle2;
    }
  }
}

.wrapper {
  & p,
  & h2,
  & h3 {
    @extend %typo-body1;
  }
}
