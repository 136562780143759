.content {
  --inset-right: calc(var(--page-inset) + 1px);
  @media (--small) {
    max-width: var(--page-inset-width);
    margin-inline: auto;
  }
  & :global(.swiper) {
    padding-inline: var(--smart-page-inset);

    @media (--small) {
      padding: 0;
    }
  }

  &__card {
    flex-shrink: 0;
    text-decoration: none;
    transition: all 250ms;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);

    & img {
      aspect-ratio: 3 / 4;
      object-fit: cover;

      overflow: hidden;
    }

    & > * {
      position: relative;
    }

    & h3 {
      @extend %typo-subtitle1;
      color: var(--color-dark);
    }

    & a {
      @extend %typo-body2;
      color: var(--color-black);
      text-underline-offset: 2px;
      line-height: 1;
    }
  }

  & :global(.swiper-wrapper) {
    margin-bottom: var(--spacing-md);
    @media (--mobile-only) {
      padding-right: 25px;
    }
  }

  & .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: auto;
    gap: var(--spacing-md);
    @media (--large) {
      width: 50%;
      margin: 0 auto;
      justify-content: center;
    }

    & .pagination {
      position: relative;
      width: 100%;
      top: unset;
      left: unset;
      bottom: unset;
    }
  }

  & .navigation {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    --svg-color: var(--color-black);
    --content-card-button-size: 24px;

    & button {
      background: unset;
      margin: 0;
      padding: 0;
      border: 0;
      appearance: none;
      cursor: pointer;
      width: var(--content-card-button-size);
      height: var(--content-card-button-size);
      &:first-child {
        rotate: 90deg;
      }

      &:last-child {
        rotate: -90deg;
      }

      & svg {
        width: var(--content-card-button-size);
        height: var(--content-card-button-size);
      }
    }
  }
}

div.slide {
  --slide-width: 70%;
  width: var(--slide-width);
  padding-bottom: var(--spacing-sm);

  @media (--small) {
    --slide-width: calc((100%) / 3 - var(--column-gap) / 2);
  }
}

.link {
  margin-block: var(--spacing-48);
}
