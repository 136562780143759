.videoWrap {
  aspect-ratio: 16/9;
}

.left {
  @extend %main-grid;

  & .videoWrap {
    @extend %main-left;
  }
}

.right {
  @extend %main-grid;

  & .videoWrap {
    @extend %main-right;
  }
}
