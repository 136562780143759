.wrapper:not(.column) {
  @extend %main-grid;

  & .inner {
    @extend %main-left-indent-small;
  }
}

.wrapper {
  & p {
    @extend %typo-subtitle2;
  }

  & h2,
  h3 {
    @extend %typo-subtitle1;
  }

  & h3 {
    font-size: var(--font-size-xl); /* 20px */
  }
}
