.title {
  display: block;
}

.richText {
  --marker-size: 10px;
  & > * {
    margin-top: var(--body-margin);
  }

  & > *:first-child {
    margin-top: 0;
  }

  & a {
    color: var(--color-text);
    @extend %typo-body2;
    font-size: inherit;
    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover {
      text-decoration: none;
    }
  }

  & p {
    margin: 1em 0;
    @extend %typo-body2;
  }

  & h2 {
    @extend %typo-subtitle1;
    margin-top: var(--section-spacing);
  }

  & h3 {
    @extend %typo-subtitle2;
    margin-top: var(--margin-spacing);
  }

  & b {
    font-weight: 500;
  }

  & ul {
    list-style-type: square;
    padding-inline-start: 1em;
    margin: 1em 0;
    & li {
      & > * {
        margin: 0;
      }
      &::marker {
        font-size: var(--marker-size);
        line-height: 1;
        vertical-align: middle;
      }
    }
  }
}
