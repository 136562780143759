.title {
  @media (--small) {
    text-align: center;
  }
}

.head {
  display: flex;

  flex-direction: column;
  margin-top: var(--spacing-md);
  gap: var(--spacing-8);

  @media (--small) {
    align-items: center;
    text-align: center;
  }

  & a {
    @extend %typo-body2;
    color: var(--color-primary);
    text-underline-offset: var(--spacing-6);
    margin-bottom: var(--spacing-xs);
  }
}

.articleGrid {
  --article-grid-top: 8px;
  --row-gap: var(--spacing-md);
  --column-gap: 16px;
  align-items: start;
  margin-top: var(--article-grid-top);
  gap: var(--row-gap) var(--column-gap);
  display: flex;
  overflow: auto;

  @media (--mobile-only) {
    gap: 0;
    margin-inline: calc(var(--page-inset) * -1);

    & .card {
      --card-width: 100%;
      flex-basis: 75%;
      flex-shrink: 0;
      flex-grow: 1;
      margin-inline: 20px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      height: 1px;
      width: var(--page-inset);
    }
  }

  @media (--small) {
    display: grid;
    grid-template-columns: 1fr;
    --article-grid-top: 50px;
    grid-template-columns: repeat(12, 1fr);

    & > div:nth-child(1) {
      grid-column: 1 / span 3;
    }

    & > div:nth-child(2) {
      grid-column: 5 / span 3;
    }

    & > div:nth-child(3) {
      grid-column: 10 / span 3;
    }
  }
}
