.root {
  display: flex;
  justify-content: center;
  background: var(--neutral-10);

  @media (--small) {
    display: block;
  }
}

.gradient {
  --gradient-box-height: 366px;
  z-index: var(--z-behind);
  height: var(--gradient-box-height);
  margin-top: -150px;
  background: linear-gradient(rgba(249, 249, 242, 1), rgba(224, 220, 205, 1));
}

.newsletter {
  &__inner {
    --newsletter-max-width: 500px;
    --newsletter-min-width: 300px;
    max-width: var(--newsletter-max-width);

    @media (--small) {
      padding-top: var(--spacing-16);
    }
  }

  &__input {
    width: 100%;
    display: block;
    background: transparent;
    border: 0;
    padding: var(--spacing-2xs);
    background: var(--color-white);
    border-bottom: 1px solid var(--neutral-6-1);
    height: 50px;
    @extend %typo-body2;

    &::placeholder {
      color: var(--color-inactive-tag);
    }

    &:focus-visible {
      outline: 1px solid var(--color-primary);
    }
  }

  &__checkbox {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-16);
    @extend %typo-caption1;
  }

  &__title {
    @extend %typo-subtitle2;
  }
}

.footer {
  --footer-min-height: 720px;
  max-width: var(--page-inset-width);
  justify-content: space-between;
  padding-block: var(--spacing-lg);
  min-height: var(--footer-min-height);
  @extend %typo-body2;
  grid-template-rows: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  position: relative;

  @media (--small) {
    @extend %main-grid;
    padding-top: 0;
    padding-bottom: var(--spacing-sm);

    & .colwrapper {
      grid-column: 5 / span 3;
    }

    & .doublecolwrapper {
      grid-column: 8 / span 5;
    }
  }
}

.logoWrapper {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-24);
  align-items: center;
  position: relative;
  z-index: 2;

  @media (--small) {
    grid-column: 1 / span 3;
    margin-top: auto;
  }
}

.logoSmall,
.logo {
  @media (--small) {
    grid-row: 2;
    display: flex;
    align-items: center;
  }

  & svg {
    width: auto;
  }
}

.logoSmall {
  --logo-width: var(--spacing-70);

  & svg {
    width: var(--logo-width);
    height: auto;
  }

  @media (--small) {
    --logo-width: var(--spacing-96);
    justify-content: center;
  }
}

.logo {
  --logo-width: var(--spacing-96);
  --logo-height: 30px;
  justify-content: flex-end;

  @media (--small) {
    --logo-width: var(--spacing-176);
    inline-size: fit-content;
  }

  & svg {
    width: auto;
    height: var(--logo-height);

    @media (--small) {
      --logo-height: 50px;
    }
  }
}

.right {
  grid-column: 1 / span 3;
  position: relative;
  z-index: 2;
  @media (--small) {
    align-items: start;
    border-top: 1px solid var(--color-primary);
    display: flex;
    flex-direction: column;
  }
}

.col {
  border-top: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;

  @media (--small) {
    margin-bottom: 0;
  }
}

.doublecolwrapper {
  display: flex;
  gap: var(--spacing-16);
}

.colwrapper,
.doublecolwrapper {
  position: relative;
  z-index: 2;
  border-top: 1px solid var(--color-primary);
}

.links {
  padding-top: var(--spacing-16);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-16);

  & a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.copy {
  padding-bottom: var(--spacing-20);
  padding-top: var(--spacing-96);

  @media (--large) {
    display: flex;
    justify-content: space-between;
  }
}

.copylinks {
  flex-direction: columns;
  gap: var(--spacing-24);
  @extend %typo-body3;
  align-items: center;
  margin-top: var(--spacing-64);

  & > div {
    gap: var(--spacing-12);
    display: flex;
  }

  @media (--large) {
    flex-direction: row;
    align-items: unset;
    margin: 0;
  }
}

.preamble {
  margin-bottom: var(--spacing-24);
  @extend %typo-body3;
}

.accordionWrapper {
  border-bottom: 1px solid var(--color-divider-contrast);
}

.trigger {
  border-top: 1px solid var(--color-divider-contrast) !important;
  border-bottom: none !important;
}

.accordion {
  &__content {
    @extend %typo-body2;
    display: flex;
    flex-direction: column;
    padding: var(--spacing-16) 0;

    a {
      padding: var(--spacing-12) 0;
    }
  }
}

.divider {
  height: var(--spacing-16);
}

.message {
  --newsletter-max-width: 500px;
  --newsletter-min-width: 300px;
  font-size: var(--font-size-m);
  max-width: var(--newsletter-max-width);
  min-width: var(--newsletter-min-width);
  color: var(--color-error);
  margin-bottom: var(--spacing-lg);

  &.success {
    margin-bottom: var(--spacing-lg);
    margin-top: var(--spacing-16);

    @media (--small) {
      margin-bottom: 0;
    }
  }
}

.inputWrap {
  display: flex;
  margin: var(--spacing-sm) 0 var(--spacing-xs);
  flex-direction: row;
  background: var(--color-white);
  position: relative;

  &:focus-visible {
    outline: 1px solid var(--color-primary);
  }
}

.submit {
  position: absolute;
  right: 1px;
  top: 1px;

  & button {
    min-width: var(--spacing-24);
    padding-inline: var(--spacing-16);
    &:hover {
      background: var(--color-white);

      & svg {
        stroke-width: 2;
      }
    }

    & div > div {
      align-items: center;
      display: flex;
    }

    & svg {
      --svg-color: var(--neutral-6-2);
      width: var(--icon-md);
      height: var(--icon-md);
    }
  }
}

.weatherControls {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  gap: 8px;

  & .button {
    @extend %typo-body2;
    border-radius: 6px;
    border: 1px solid #707070;
    background: #e0dccd;
    padding: 4px 8px;
    cursor: pointer;
    font-size: 12px;
    color: #000;

    &:hover {
      background: #d0cdc2;
    }

    &.active {
      background: var(--color-dark);
      border-color: var(--color-dark);
      color: var(--color-white);
    }
  }
}

.weatherWrap {
  grid-column: 12 span;
  & canvas {
    width: 100% !important;
    height: 100% !important;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    animation: fadeIn 400ms;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.formWrapper {
  margin-top: var(--spacing-16);
}
