.link {
  text-decoration: none;
  color: var(--color-text);

  &__underline {
    text-decoration: underline;
  }

  /* &:hover {
    opacity: 1;
  }

  @extend %typo-body2; */
}
