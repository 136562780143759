.container {
  container-name: mediaGallery;
  container-type: inline-size;
  padding: var(--spacing-md) 0;
}

.head {
  text-align: center;
  margin-top: var(--spacing-md);
  margin-bottom: var(--spacing-xs);
  padding-bottom: var(--spacing-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  @media (--small) {
    padding-bottom: var(--spacing-lg);
  }

  & a {
    @extend %typo-body2;
    color: var(--color-primary);
    text-underline-offset: var(--spacing-6);
    margin-bottom: var(--spacing-xs);
  }
}

.head h2 {
  @extend %typo-headline2;
  font-size: 22px;

  @media (--small) {
    font-size: var(--subtitle2-size);
  }
}

.card {
  position: relative;
}

.link {
  margin-top: var(--spacing-48);
}
