.wrapper {
  gap: 46px var(--column-gap);

  @media (--small) {
    gap: var(--row-gap) var(--column-gap);
  }
}

.cardListLarge {
  display: grid;
  grid-template-columns: 1fr;
  --grid-gap: var(--spacing-xs);
  --pc-min-width: 250px;

  @media (--small) {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--grid-gap);
  }

  @media (--large) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.cardListSmall {
  display: grid;
  grid-template-columns: 1fr;
  --grid-gap: var(--spacing-xs);
  --pc-min-width: 250px;
  row-gap: var(--spacing-md);

  @media (--small) {
    grid-template-columns: repeat(2, minmax(var(--pc-min-width), 1fr));
    gap: var(--grid-gap);
  }

  @media (--large) {
    grid-template-columns: repeat(3, minmax(var(--pc-min-width), 1fr));
  }
}

.slide {
  width: 65%;
  @media (--large) {
    width: calc(min(25%, calc(var(--page-inset-width) / 4)) - 45px);
  }
}

.swiper > div {
  margin-inline: var(--smart-page-inset);
}

.navigation {
  display: flex;
}

.navigationLeft,
.navigationRight {
  --side-offset: 2px;
  --swiper-bottom-space: calc(var(--spacing-16) + var(--arrow-size));
  --side: 48px;
  display: none;
  @media (--small) {
    display: block;
    position: absolute;
    top: calc(50% - var(--swiper-bottom-space));
    left: var(--side-offset);
    transition: all 300ms;
    opacity: 1;
    z-index: 1;
    cursor: pointer;
    border: 0;
    border-radius: 0;
    background: var(--neutral-2);
    --svg-color: var(--color-dark);
    width: var(--size);
    height: var(--size);
    transform: rotate(90deg);

    & svg {
      width: calc(var(--size) / 2);
      height: calc(var(--size) / 2);
    }

    &:hover {
      background: var(--neutral-3);
    }

    &[disabled] {
      opacity: 0;
    }
  }
}
.navigationRight {
  @media (--small) {
    left: auto;
    right: var(--side-offset);
    transform: rotate(270deg);
  }
}
