.wrap {
  --_pc-color: #e0dccd;
  aspect-ratio: var(--hero-aspect);
  background: var(--_pc-color);
  padding: 24px 0;
  @media (--small) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.inner {
  @media (--small) {
    width: 65%;
  }
}
