.item {
  /* padding-bottom: 2rem; */
  border-bottom: 1px solid var(--color-divider);
  transition: border 250ms;
  display: block;
  --svg-color: var(--color-black);

  & .icon {
    margin-left: auto;
    display: flex;
    align-items: center;
    height: 100%;
    & svg {
      width: var(--icon-sm);
      height: var(--icon-sm);
    }

    &.mobileMenu {
      & svg {
        width: var(--icon-md);
        height: var(--icon-md);
      }
    }
  }

  &[open] {
    & .icon {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.trigger {
  position: relative;
  list-style: none;
  padding-block: var(--accordion-spacing);
  @extend %typo-body2;
  &::marker, /* Latest Chrome, Edge, Firefox */ 
  &::-webkit-details-marker /* Safari */ {
    display: none;
  }
  cursor: pointer;
  display: flex;
  align-items: center;
}

.content {
  padding-bottom: var(--accordion-spacing);

  & h3 {
    @extend %typo-subtitle2;
    margin-bottom: var(--spacing-8);
  }

  & p + h3 {
    margin-top: var(--spacing-24);
  }

  & p + p {
    margin-top: 1rem;
  }

  & p a {
    color: var(--color-primary);
  }
}
