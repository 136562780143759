.quote {
  margin: 0 auto;
  max-width: var(--content-width);
  @extend %typo-body1;

  & cite {
    @extend %typo-body2;
    font-style: normal;
  }
}

.wrapper:not(.column) {
  @extend %main-grid;

  & .inner {
    @extend %main-right;
  }
}
