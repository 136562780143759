.pageTop {
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-xs);

  & .inner {
    max-width: var(--text-width);
  }

  & .title {
    @extend %typo-headline1;
  }

  & .text {
    @extend %typo-subtitle2;
    text-wrap: pretty;
    margin-top: var(--spacing-16);
  }
}
