.text {
  --intro-width: 770px;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: var(--spacing-md);
  @extend %typo-headline2;

  & .textWrap {
    display: flex;
    max-width: var(--intro-width);
    align-items: center;
    text-align: center;
    row-gap: var(--spacing-xs);
    flex-direction: column;
    margin-bottom: var(--spacing-16);
  }

  & .subText {
    @extend %typo-body1;
    font-size: var(--font-size-xl);

    @media (--small) {
      font-size: var(--font-size-3xl);
    }
  }
}
